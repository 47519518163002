// feature flags

export const FEATURE_FLAG_ENUMS = {
  EXPAND_SPECIALIZED_WORKLOADS_ENABLED: "expand_specialized_workloads_enabled",
  FR_EXTENDS_INTERNAL: "fr_extends_internal",
  INTERNAL_FUND_REQUEST_LISTING_PARTITION:
    "internal_fund_request_listing_partition",
  DISPLAY_MDF_BANNER: "display_mdf_banner",
  DISPLAY_OPPORTUNITY_ID_HYPERLINK: "display_opportunity_id_hyperlink",
  CASH_CLAIM_ATTACHMENT_INTERNAL: "cash_claim_attachment_internal",
  NEW_SEARCH_BAR_INTERNAL_DASHBOARD: "new_search_bar_internal_dashboard",
  PIF_ENABLED: "pif_enabled",
  SELF_SERVICE_INTERNAL: "self_service_internal",
  SELF_SERVICE_AUDIT_CODE: "self_service_audit_code",
  SELF_SERVICE_REJECTION_REASON_CODE: "self_service_rejection_reason_code",
  SELF_SERVICE_FUND_REQUEST_MDF_ACTIVITY_TYPE:
    "self_service_fund_request_mdf_activity_type",
  SELF_SERVICE_HELPER_TEXT: "self_service_helper_text",
  SELF_SERVICE_AUTHORIZER: "self_service_authorizer",
  MPE_AND_ARR_VALIDATION_FOR_MAP_OPPORTUNITY:
    "mpe_and_arr_validation_for_map_opportunity",
  BULK_ACTIONS: "bulk_actions",
  BULK_ACTIONS_EXPIRE: "bulk_actions_expire",
  BULK_ACTIONS_UPDATE_INVOICE: "bulk_actions_update_invoice",
  HINDER_FC_SUBMISSION: "hinder_fc_submission",
  DISPLAY_MPE_RECORDS_table: "display_mpe_records_table",
  MAP_WF_MPE_POPULATION: "map_wf_mpe_population",
  ESTIMATED_REQUESTED_AMOUNT_USD: "estimated_requested_amount_usd",
  ISV_WMP_CONSENT_ENABLED: "isv_wmp_consent_enabled",
  USER_PERSISTED_SETTINGS_ENABLED: "user_persisted_settings_enabled",
  MAP_2024_ENABLED: "map_2024_enabled",
  OLD_MAP_DISABLED: "old_map_disabled",
  UPDATE_CLAIM_AMOUNT_INTERNAL_ENABLE: "update_claim_amount_internal_enable",
  PENDO_EXTERNAL_ENABLED: "pendo_external_enabled",
  BULK_ACTIONS_MAP_UNLOCK: "bulk_actions_map_unlock",
  VMWARE_MAP_ENABLED: "vmware_map_enabled",
  VMWARE_BONUS_MSP_OPTION_ENABLED: "vmware_bonus_msp_option_enabled",
  MAP_FR_EXTERNAL_EXTENDS: "map_fr_external_extends",
  MAP_FR_INTERNAL_EXTENDS: "map_fr_internal_extends",
  AIBA_FRONTEND_ENABLED: "aiba_frontend_enabled",
  AIBA_ASYNC_ENABLED: "aiba_async_enabled",
};
