import React from "react";

import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";
import BreadcrumbGroup from "@amzn/awsui-components-react/polaris/breadcrumb-group";
import {
  HelpPanelContentContext,
  HelpPanelContext,
} from "shared/util/context/help/HelpContext";
import { getFundRequestId } from "external/util/common/helper";
import EditMigrationEligibilityContainer from "external/programs/migration-acceleration-program/2024/fund-request/components/edit/eligibility/EditMigrationEligibilityContainer";

const EditMigrationEligibilityPage = () => {
  const { isOpen, toggle } = React.useContext(HelpPanelContext);
  const { getHelpContent } = React.useContext(HelpPanelContentContext);
  const Panel = getHelpContent();
  return (
    <AppLayout
      breadcrumbs={
        <BreadcrumbGroup
          items={[
            { text: "Dashboard", href: "/dashboard" },
            {
              text: "Migration acceleration program",
              href: `/fund-requests/${getFundRequestId()}/details`,
            },
            { text: "Edit migration eligibility", href: "#" },
          ]}
        />
      }
      contentType="form"
      toolsOpen={isOpen}
      onToolsChange={toggle}
      tools={Panel}
      headerSelector=".navbar"
      content={<EditMigrationEligibilityContainer />}
      navigationHide
    />
  );
};

export default EditMigrationEligibilityPage;
