import React from "react";
import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";
import BreadcrumbGroup from "@amzn/awsui-components-react/polaris/breadcrumb-group";
import FundRequestCreationView from "external/programs/migration-acceleration-program/2024/fund-request/views/create/FundRequestCreationView";
import {
  HelpPanelContentContext,
  HelpPanelContext,
} from "shared/util/context/help/HelpContext";

const FundRequestCreationPage = () => {
  const { isOpen, toggle } = React.useContext(HelpPanelContext);
  const { getHelpContent } = React.useContext(HelpPanelContentContext);
  const Panel = getHelpContent();

  return (
    <AppLayout
      breadcrumbs={
        <BreadcrumbGroup
          items={[
            { text: "Dashboard", href: "/dashboard" },
            { text: "Migration acceleration program", href: "#" },
          ]}
        />
      }
      contentType="form"
      toolsOpen={isOpen}
      onToolsChange={toggle}
      tools={Panel}
      headerSelector=".navbar"
      footerSelector="#f"
      content={<FundRequestCreationView />}
      navigationHide
    />
  );
};

export default FundRequestCreationPage;
