import React, { useContext } from "react";

import Header from "@amzn/awsui-components-react/polaris/header";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import { Button } from "@amzn/awsui-components-react/polaris";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import ApproveAction from "internal/programs/migration-acceleration-program/2024/fund-request/components/details/header/actions/ApproveAction";
import ChangeAssigneeAction from "internal/programs/migration-acceleration-program/2024/fund-request/components/details/header/actions/ChangeAssigneeAction";
import RejectAction from "internal/programs/migration-acceleration-program/2024/fund-request/components/details/header/actions/RejectAction";
import ConditionalField from "shared/components/common/Conditional/ConditionalField";
import CompleteAction from "internal/programs/migration-acceleration-program/2024/fund-request/components/details/header/actions/CompleteAction";
import {
  isAdmin,
  isApprovable,
  isAssigned,
  isSubmitted,
  showCompleteAction,
} from "internal/programs/migration-acceleration-program/2024/fund-request/components/details/header/actions/util";
import { NotificationsContext } from "shared/util/context/notifications/NotificationsContext";
import { Extend } from "internal/components/Review/Extend";

export interface IFundRequestDetailsHeaderBase {
  data: IGenericObject;
  refresh: () => void;
  loading: boolean;
}

const FundRequestDetailsHeader = ({
  data,
  refresh,
  loading,
}: IFundRequestDetailsHeaderBase) => {
  const { setNotifications } = useContext(NotificationsContext);
  const fundRequestId = data?.fundRequestId;
  const fundRequestStage = data?.stage;

  return (
    <Header
      description="Migration acceleration program (MAP) fund request"
      variant="h1"
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <Button onClick={refresh} iconName="refresh" loading={loading}>
            Refresh
          </Button>
          <ConditionalField showField={isApprovable({ data })}>
            <ApproveAction loading={loading} data={data} />
          </ConditionalField>
          <ConditionalField showField={isApprovable({ data })}>
            <RejectAction loading={loading} data={data} />
          </ConditionalField>
          <ConditionalField
            showField={
              isSubmitted({ data }) &&
              (isAssigned({ userId: data?.assignee?.userId }) || isAdmin())
            }
          >
            <ChangeAssigneeAction loading={loading} data={data} />
          </ConditionalField>
          {/*TODO: integrate with Audit API*/}
          {/*https://taskei.amazon.dev/tasks/APN-48287*/}
          {/*<Button>Audit</Button>*/}

          <ConditionalField showField={fundRequestId && fundRequestStage}>
            <Extend
              fundRequests={[
                { fundRequestId: fundRequestId, stage: fundRequestStage },
              ]}
              setNotificationsItems={setNotifications}
              loading={loading}
            />
          </ConditionalField>
          <ConditionalField showField={showCompleteAction({ data })}>
            <CompleteAction loading={loading} />
          </ConditionalField>
        </SpaceBetween>
      }
    >
      {fundRequestId}
    </Header>
  );
};

export default FundRequestDetailsHeader;
