import { IFieldSchema } from "external/programs/migration-acceleration-program/2024/fund-request/types/SchemaTypes";
import { FieldType } from "shared/programs/migration-acceleration-program/2024/fund-request/components/fields/FieldComponent";
import { STAGE } from "shared/util/constants/fundRequestStatusType";
import { ValidationType } from "shared/programs/migration-acceleration-program/2024/fund-request/components/fields/validations";
import { isMobilizeMapLarge } from "shared/programs/migration-acceleration-program/2024/fund-request/utils/helper";
import { MAP2024_MIGRATION_PHASE } from "external/programs/migration-acceleration-program/2024/fund-request/components/create/eligibility/util";

export const MigrationDetailsEditSchema: IFieldSchema[] = [
  {
    label: "Migration phase",
    keyName: "migrationPhase",
    placeholder: "-",
    fieldType: FieldType.Input,
    editableStages: [],
  },
  {
    label: "Annual recurring revenue (ARR) (USD)",
    keyName: "arrUsdAmount",
    placeholder: "-",
    fieldType: FieldType.Input,
    editableStages: [],
    validations: [ValidationType.ValidNumber],
  },
  {
    label: "Migration Eligible ARR (USD)",
    keyName: "eligibleArrUsdAmount",
    placeholder: "-",
    fieldType: FieldType.Input,
    editableStages: [STAGE.BUSINESS_APPROVAL],
    validations: [ValidationType.ValidNumber],
  },
  {
    label: "Over 40% of ARR is modernization services",
    keyName: "modernizationIncentiveEligible",
    placeholder: "-",
    fieldType: FieldType.Checkbox,
    editableStages: [STAGE.BUSINESS_APPROVAL],
    conditional: ({ data }) => (data && isMobilizeMapLarge(data)) || false,
  },
  {
    label: "Modernization ARR (USD)",
    keyName: "modernizationArrUsdAmount",
    placeholder: "-",
    fieldType: FieldType.Input,
    editableStages: [STAGE.BUSINESS_APPROVAL],
    validations: [ValidationType.ValidNumber],
    conditional: ({ data }) =>
      data &&
      isMobilizeMapLarge(data) &&
      data["modernizationIncentiveEligible"],
  },
];

export const MigrationDetailsEditSchemaWithVMware: IFieldSchema[] =
  MigrationDetailsEditSchema.concat({
    label: "VMware acknowledgement",
    keyName: "vmwareAcknowledgement",
    fieldType: FieldType.Checkbox,
    editableStages: [STAGE.BUSINESS_APPROVAL],
    conditional: ({ data }) =>
      data?.migrationPhase === MAP2024_MIGRATION_PHASE.MOBILIZE,
  });

export const MigrationDetailsEditSchemaWithVMwareAndVMwareMSP: IFieldSchema[] =
  MigrationDetailsEditSchemaWithVMware.concat({
    label: "VMware MSP acknowledgement",
    keyName: "vmwareMSPAcknowledgement",
    fieldType: FieldType.Checkbox,
    editableStages: [STAGE.BUSINESS_APPROVAL],
    conditional: ({ data }) => data?.vmwareAcknowledgement,
  });
