import React, { useEffect } from "react";
import {
  NonCancelableCustomEvent,
  RadioGroup,
} from "@amzn/awsui-components-react";
import {
  FormField,
  RadioGroupProps,
  SpaceBetween,
} from "@amzn/awsui-components-react/polaris";
import { IClaimActualsContainerProps } from "external/programs/migration-acceleration-program/2024/fund-request/views/MAPCashClaimActualsSubmission";

const VMwareMSPRadioGroup = ({
  data,
  setData,
}: IClaimActualsContainerProps) => {
  useEffect(() => {
    setData((prev) => ({
      ...prev,
      isVMwareManagedServicesInScope: data?.vmwareMSPAcknowledgement
        ? "yes"
        : "no",
    }));
  }, []);

  const extractIsVMwareManagedServicesInScopeValueFromData = () => {
    if (data?.isVMwareManagedServicesInScope === undefined || null) {
      return "yes";
    }
    return data?.isVMwareManagedServicesInScope;
  };

  const radioItems: RadioGroupProps.RadioButtonDefinition[] = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
  ];

  return (
    <FormField
      label="Is managed services in scope"
      description="Is managed services still in the scope of services being offered to the customer?"
    >
      <SpaceBetween size="l">
        <RadioGroup
          onChange={(
            event: NonCancelableCustomEvent<RadioGroupProps.ChangeDetail>
          ) => {
            setData((prev) => ({
              ...prev,
              isVMwareManagedServicesInScope: event.detail.value,
            }));
          }}
          value={extractIsVMwareManagedServicesInScopeValueFromData()}
          items={radioItems}
        />
      </SpaceBetween>
    </FormField>
  );
};

export default VMwareMSPRadioGroup;
