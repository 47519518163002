import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import { ColumnLayout, CopyToClipboard } from "@amzn/awsui-components-react";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import React, { ReactElement } from "react";
import ValueWithLabel from "shared/programs/migration-acceleration-program/2024/fund-request/components/fields/ValueWithLabel";
import { display } from "shared/util/common/display";
import { longLocalDateFormat } from "shared/util/common/date";
import { formatCurrency } from "shared/programs/migration-acceleration-program/2024/fund-request/api/adapters";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import {
  claimStagesKeys,
  showFieldByStage,
} from "shared/programs/migration-acceleration-program/2024/fund-request/util/claims";
import ConditionalField from "shared/components/common/Conditional/ConditionalField";
import useFeatureFlag from "shared/util/hooks/useFeatureFlag";
import { FEATURE_FLAG_ENUMS } from "shared/util/constants/featureFlagValues";

const ReadOnlyCashClaim = ({
  headerTitle,
  data,
  claim,
  actions = <></>,
}: {
  headerTitle: string;
  data: IGenericObject;
  claim: IGenericObject;
  actions?: ReactElement;
}) => {
  if (!data) return <></>;

  const localCurrency = data?.currency;
  const currentStage = claim?.claimStage;
  const vmwareClaim = claim?.vmwareClaim;
  const vmwareMSPAcknowledgement = claim?.vmwareMSPAcknowledgement;

  const vmwareEnabledFlag = useFeatureFlag(
    FEATURE_FLAG_ENUMS.VMWARE_MAP_ENABLED
  );
  const vmwareMSPEnabledFlag = useFeatureFlag(
    FEATURE_FLAG_ENUMS.VMWARE_BONUS_MSP_OPTION_ENABLED
  );

  return (
    <Container
      header={
        <Header variant="h2" actions={actions}>
          {headerTitle}
        </Header>
      }
    >
      <SpaceBetween size="l">
        <ValueWithLabel label="Cash claim ID">
          <CopyToClipboard
            variant="inline"
            textToCopy={display({ data: claim, key: "claimId" }) as string}
            copySuccessText="Copied to clipboard."
            copyErrorText="Failed to copy to clipboard."
          />
        </ValueWithLabel>
        <ColumnLayout columns={3} variant="text-grid">
          <SpaceBetween size="l">
            <ValueWithLabel label="Claim due date">
              {display({
                data: claim,
                key: "claimDueDate",
                format: (value) => longLocalDateFormat(value as string),
              })}
            </ValueWithLabel>
            <ValueWithLabel label={`Claim amount (${localCurrency})`}>
              {display({
                data: claim,
                key: "claimAmount",
                format: (value) => formatCurrency(value as string),
              })}
            </ValueWithLabel>{" "}
            <ConditionalField
              showField={showFieldByStage({
                stage: currentStage,
                visibleStage: claimStagesKeys.claim_approval,
              })}
            >
              <ValueWithLabel label={`Milestone end date`}>
                {display({
                  data: claim,
                  key: "endDate",
                  format: (value) => longLocalDateFormat(value as string),
                })}
              </ValueWithLabel>
            </ConditionalField>
            <ConditionalField
              showField={showFieldByStage({
                stage: currentStage,
                visibleStage: claimStagesKeys.claim_approval,
              })}
            >
              <ValueWithLabel label="Invoice currency">
                {display({
                  data: claim,
                  key: "localCurrency",
                })}
              </ValueWithLabel>
            </ConditionalField>
            <ConditionalField showField={vmwareEnabledFlag && vmwareClaim}>
              <ValueWithLabel label="Total number of servers in scope for migration to AWS">
                {display({
                  data: claim,
                  key: "totalNumberOfWorkloadsInUse",
                })}
              </ValueWithLabel>
            </ConditionalField>
            <ConditionalField
              showField={
                vmwareMSPEnabledFlag && vmwareClaim && vmwareMSPAcknowledgement
              }
            >
              <ValueWithLabel label="Is managed services in scope">
                {display({
                  data: claim,
                  key: "isVMwareManagedServicesInScope",
                  format: (value) => (value ? "yes" : "no"),
                })}
              </ValueWithLabel>
            </ConditionalField>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ConditionalField
              showField={showFieldByStage({
                stage: currentStage,
                visibleStage: claimStagesKeys.claim_approval,
              })}
            >
              <ValueWithLabel label={`Actual claim amount (${localCurrency})`}>
                {display({
                  data: claim,
                  key: "claimLocalAmount",
                })}
              </ValueWithLabel>
            </ConditionalField>
            <ConditionalField
              showField={showFieldByStage({
                stage: currentStage,
                visibleStage: claimStagesKeys.claim_approval,
              })}
            >
              <ValueWithLabel label="AWS account ID for actual delivery">
                {display({ data: claim, key: "awsAccountId" })}
              </ValueWithLabel>
            </ConditionalField>

            <ConditionalField
              showField={showFieldByStage({
                stage: currentStage,
                visibleStage: claimStagesKeys.claim_approval,
              })}
            >
              <ValueWithLabel label="Actual production date">
                {display({
                  data: claim,
                  key: "productionDate",
                  format: (value) => longLocalDateFormat(value as string),
                })}
              </ValueWithLabel>
            </ConditionalField>
            <ConditionalField
              showField={showFieldByStage({
                stage: currentStage,
                visibleStage: claimStagesKeys.claim_approval,
              })}
            >
              <ValueWithLabel label={`Invoice amount (${localCurrency})`}>
                {display({
                  data: claim?.invoice,
                  key: "invoiceLocalAmount",
                })}
              </ValueWithLabel>
            </ConditionalField>
            <ConditionalField showField={vmwareEnabledFlag && vmwareClaim}>
              <ValueWithLabel label="Number of VMs hosted on VMware in scope for migration to AWS">
                {display({
                  data: claim,
                  key: "numberOfVmwareToMigrate",
                })}
              </ValueWithLabel>
            </ConditionalField>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ConditionalField
              showField={showFieldByStage({
                stage: currentStage,
                visibleStage: claimStagesKeys.claim_approval,
              })}
            >
              <ValueWithLabel label="Cash claim stage">
                {display({ data: claim, key: "stage" })}
              </ValueWithLabel>
            </ConditionalField>

            <ConditionalField
              showField={showFieldByStage({
                stage: currentStage,
                visibleStage: claimStagesKeys.claim_approval,
              })}
            >
              <ValueWithLabel label="Milestone start date">
                {display({
                  data: claim,
                  key: "startDate",
                  format: (value) => longLocalDateFormat(value as string),
                })}
              </ValueWithLabel>
            </ConditionalField>
            <ConditionalField
              showField={showFieldByStage({
                stage: currentStage,
                visibleStage: claimStagesKeys.claim_approval,
              })}
            >
              <ValueWithLabel label="Scheduled paid date">
                {display({
                  data: claim?.invoice,
                  key: "scheduledPaymentDate",
                  format: (value) => longLocalDateFormat(value as string),
                })}
              </ValueWithLabel>
            </ConditionalField>
            <ConditionalField showField={vmwareEnabledFlag && vmwareClaim}>
              <ValueWithLabel label="Tools used for generating migration report">
                {display({
                  data: claim,
                  key: "toolsUsedForGeneratingMigrationReport",
                  format: (value) => value.replace(/,/g, ", "),
                })}
              </ValueWithLabel>
            </ConditionalField>
          </SpaceBetween>
        </ColumnLayout>
        <ConditionalField
          showField={showFieldByStage({
            stage: currentStage,
            visibleStage: claimStagesKeys.claim_approval,
          })}
        >
          <ValueWithLabel label="Invoice description">
            {display({ data: claim?.invoice, key: "description" })}
          </ValueWithLabel>
        </ConditionalField>
      </SpaceBetween>
    </Container>
  );
};

export default ReadOnlyCashClaim;
