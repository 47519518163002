import React, { useEffect, useState } from "react";
import { Container, Header } from "@amzn/awsui-components-react/polaris";
import { FileUploadComponent } from "shared/programs/migration-acceleration-program/2024/fund-request/components/attachments/FileUploadComponent";
import { IContainerProps } from "shared/programs/migration-acceleration-program/2024/fund-request/types/ContainerTypes";
import {
  equals,
  getArtifacts,
} from "shared/programs/migration-acceleration-program/2024/fund-request/components/attachments/util";
import { IArtifactFileModel } from "shared/programs/migration-acceleration-program/2024/fund-request/components/attachments/types/fileTypes";
import { MAX_FILE_SIZE_IN_MB } from "external/programs/migration-acceleration-program/2024/fund-request/components/create/wizard/Steps/Artifacts/util";
import { useParams } from "react-router-dom";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import { attachFileToCashClaim } from "external/util/services/data/FundRequestService";
import { Link } from "@amzn/awsui-components-react";
import { MANAGED_SERVICES_AGREEMENT_TEMPLATE_LINK } from "shared/util/constants/constantValues";

const VMwareManagedServices = ({ data }: IContainerProps) => {
  const { claimId } = useParams<IGenericObject>();
  const [files, setFiles] = React.useState<File[]>([]);
  const [fileIdToAttributes, setFileIdToAttributes] =
    useState<IArtifactFileModel>({});

  useEffect(() => {
    const documents = data?.artifacts?.documents;
    if (!documents) return;
    const artifacts = getArtifacts({
      documents,
      filter: (actualType) => equals(actualType, "Managed Services Agreement"),
    });
    setFiles(artifacts.files || []);
    setFileIdToAttributes(artifacts.fileIdToAttributes);
  }, [data]);

  const claimAttach = async ({
    body,
    fundRequestId,
  }: {
    body: unknown;
    fundRequestId: unknown;
  }) => {
    const newBody = {
      ...(body as IGenericObject),
      fundClaimId: claimId,
    };
    const response = await attachFileToCashClaim({
      body: newBody,
      fundRequestId,
      fundClaimId: claimId,
    });
    return response;
  };

  return (
    <Container
      header={<Header variant="h2">Managed Services Agreement</Header>}
    >
      <FileUploadComponent
        label={""}
        artifacts={{ files, fileIdToAttributes }}
        updateFiles={setFiles}
        setFileIdToAttributes={setFileIdToAttributes}
        supportingDocumentType="Managed Services Agreement"
        description={
          <>
            Select the link to download a new{" "}
            <Link
              external
              variant="primary"
              fontSize="inherit"
              href={MANAGED_SERVICES_AGREEMENT_TEMPLATE_LINK}
            >
              Managed services agreement template
            </Link>
            .
          </>
        }
        constraintText={`File size limit: ${MAX_FILE_SIZE_IN_MB} MB`}
        attach={claimAttach}
        claimId={claimId}
      />
    </Container>
  );
};

export default VMwareManagedServices;
