import React from "react";
import { Alert } from "@amzn/awsui-components-react";
import ConditionalField from "shared/components/common/Conditional/ConditionalField";

import {
  isAssessPhase,
  isMobilizePhase,
} from "shared/programs/migration-acceleration-program/2024/fund-request/utils/helper";

import { removeCurrencyFormatting } from "shared/programs/migration-acceleration-program/2024/fund-request/util/currency";

const MAP_LITE_QUALIFICATION_MESSAGE = (
  <p>
    To qualify for the mobilize phase, your opportunity must be in the{" "}
    <strong> Committed</strong> or <strong>Launched </strong>
    stage.
  </p>
);
const MAP_LARGE_QUALIFICATION_MESSAGE = (
  <p>
    To qualify for the mobilize phase, your opportunity must be in the{" "}
    <strong> Business validation</strong> stage or <strong>higher </strong>.
  </p>
);
const ASSESS_QUALIFICATION_MESSAGE = (
  <p>
    To qualify for the assess phase, your opportunity must be in the{" "}
    <strong> Technical validation</strong> stage or <strong>higher </strong>.
  </p>
);
const MAP_LARGE_THRESHOLD = 500000;

interface OpportunityInfoAlertProps {
  migrationPhase: string;
  arrString: string;
}

const getQualificationMessage = ({
  migrationPhase,
  arrString,
}: {
  migrationPhase: string;
  arrString: string;
}) => {
  if (isAssessPhase(migrationPhase)) {
    return ASSESS_QUALIFICATION_MESSAGE;
  }
  const arr = Number(removeCurrencyFormatting(arrString));
  if (isNaN(arr)) return;

  if (isMobilizePhase(migrationPhase) && arr >= MAP_LARGE_THRESHOLD) {
    return MAP_LARGE_QUALIFICATION_MESSAGE;
  }

  return MAP_LITE_QUALIFICATION_MESSAGE;
};

const OpportunityQualificationAlert = ({
  migrationPhase,
  arrString,
}: OpportunityInfoAlertProps) => {
  const message = getQualificationMessage({ migrationPhase, arrString });
  return (
    <ConditionalField showField={!!migrationPhase && !!message}>
      <Alert>{message}</Alert>
    </ConditionalField>
  );
};

export default OpportunityQualificationAlert;
