import React, { useContext, useEffect, useState } from "react";
import CashClaimPlans from "../components/submit/CashClaimActualsContainer/CashClaimPlans";
import { Header, SpaceBetween } from "@amzn/awsui-components-react";
import CashClaimActuals from "../components/submit/CashClaimActualsContainer/CashClaimActuals";
import { Button, Form } from "@amzn/awsui-components-react/polaris";
import { IContainerProps } from "shared/programs/migration-acceleration-program/2024/fund-request/types/ContainerTypes";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import {
  getFundRequest,
  submitCashClaim,
  updateCashClaim,
} from "external/util/services/data/FundRequestService";
import { useHistory, useParams } from "react-router-dom";
import { hasError, retryApiCall } from "shared/util/services/data/DataService";
import { RouteOrigin } from "shared/util/constants/RouteState";
import SystemErrorAlert from "shared/programs/migration-acceleration-program/2024/fund-request/components/common/SystemErrorAlert";
import CustomerSignOff from "external/programs/migration-acceleration-program/2024/fund-request/components/submit/CashClaimActualsContainer/CustomerSignOff";
import ClaimAdditionalArtifactsSection from "external/programs/migration-acceleration-program/2024/fund-request/components/submit/CashClaimActualsContainer/ClaimAdditionalArtifactsSection";
import { claimAdapter } from "shared/programs/migration-acceleration-program/2024/fund-request/api/adapters";
import { GENERIC_ERROR_MESSAGE } from "external/programs/migration-acceleration-program/2024/fund-request/components/create/wizard/Steps/Artifacts/util";
import InfoLink from "shared/components/InfoLink";
import { HelpPanelContentContext } from "shared/util/context/help/HelpContext";
import { NotificationsContext } from "shared/util/context/notifications/NotificationsContext";
import {
  Error as ResultError,
  handleApiError,
  handleErrors,
  Result,
  ResultErrorType,
  ResultType,
} from "shared/util/api/util";
import { removeCurrencyFormatting } from "shared/programs/migration-acceleration-program/2024/fund-request/util/currency";
import VMwareMigrationCompletion from "../components/submit/CashClaimActualsContainer/VmwareMigrationCompletion";
import ConditionalField from "../../../../../../shared/components/common/Conditional/ConditionalField";
import useFeatureFlag from "../../../../../../shared/util/hooks/useFeatureFlag";
import { FEATURE_FLAG_ENUMS } from "../../../../../../shared/util/constants/featureFlagValues";
import { formatNumberInput } from "../components/create/wizard/util";
import VMwareManagedServices from "../components/submit/CashClaimActualsContainer/VmwareManagedServices";

export interface IClaimActualsContainerProps extends IContainerProps {
  radioGroupErrorText: string;
  setRadioGroupErrorText: React.Dispatch<React.SetStateAction<string>>;
}

const MAPCashClaimActualsSubmission = () => {
  const { id, claimId } = useParams<IGenericObject>();
  const history = useHistory();
  const { addNotification } = useContext(NotificationsContext);

  const { setKey, setHelpPanelContent } = React.useContext(
    HelpPanelContentContext
  );

  const [data, setData] = useState<IGenericObject>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const vmwareEnabledFlag = useFeatureFlag(
    FEATURE_FLAG_ENUMS.VMWARE_MAP_ENABLED
  );
  const vmwareMSPEnabledFlag = useFeatureFlag(
    FEATURE_FLAG_ENUMS.VMWARE_BONUS_MSP_OPTION_ENABLED
  );

  const loadDataFromResponse = (response: IGenericObject) => {
    const fundRequest = response?.fundRequest;
    const claims = response?.claims;
    const claim = claims[claimId];

    if (!claim.startDate) {
      claim.startDate = fundRequest?.project?.startDate;
    }
    if (!claim.endDate) {
      claim.endDate = new Date().toISOString();
    }

    const state = claimAdapter(claim, fundRequest);
    if (state) setData(state);
  };

  useEffect(() => {
    setKey("claim.page");
    const fetchFundRequest = async ({ id }: { id: string }) => {
      setIsLoading(true);
      try {
        const response: IGenericObject = await getFundRequest(id);
        if (hasError(response)) {
          addNotification({
            content: response.message || GENERIC_ERROR_MESSAGE,
            type: "error",
          });
          return;
        }
        loadDataFromResponse(response);
      } catch (err) {
        addNotification({
          content: GENERIC_ERROR_MESSAGE,
          type: "error",
        });
        console.error(err);
        return;
      } finally {
        setIsLoading(false);
      }
    };

    fetchFundRequest({ id });
  }, []);

  const [errors, setErrors] = useState<IGenericObject>({});
  const [formError, setFormError] = useState("");
  const [radioGroupErrorText, setRadioGroupErrorText] = useState("");

  const vmwareClaim = data?.vmwareClaim;
  const vmwareMSPAcknowledgement = data?.vmwareMSPAcknowledgement;
  const isVMwareManagedServicesInScope = data?.isVMwareManagedServicesInScope;

  const onChange = ({
    key,
    value,
  }: {
    key: string;
    value: string | undefined | boolean;
  }) => {
    setErrors({});
    setData((prevData) => ({ ...prevData, [key]: value }));
  };

  const onError = ({ key, value }: { key: string; value: string }) => {
    setErrors((prevErrors) => ({ ...prevErrors, [key]: value }));
  };

  const stateProps: IClaimActualsContainerProps = {
    data,
    errors,
    formError,
    onChange,
    onError,
    setData,
    setErrors,
    setFormError,
    radioGroupErrorText,
    setRadioGroupErrorText,
  };

  const formatDatetimeType = (value: string) => {
    if (!value) {
      return null;
    }
    const date = new Date(value);
    return {
      timestamp: date.getTime(),
    };
  };

  const createPayload = () => {
    const claimAmount = {
      units: Number(removeCurrencyFormatting(data?.claimAmount)),
      nanos: 0,
      currency: data?.currency,
    };
    const awsAccountId = data?.awsAccountId;
    const customerSignOffAttached = data?.customerSignOffAttached;
    const startDate = formatDatetimeType(data?.startDate);
    const endDate = formatDatetimeType(data?.endDate);
    const hasSowChanged = data?.hasSowChanged;
    const productionDate = formatDatetimeType(data?.productionDate);
    const numberOfVmwareToMigrate = formatNumberInput(
      data?.numberOfVmwareToMigrate
    );
    const totalNumberOfWorkloadsInUse = formatNumberInput(
      data?.totalNumberOfWorkloadsInUse
    );
    const toolsUsedForGeneratingMigrationReport =
      (data?.toolsUsedForGeneratingMigrationReport &&
        data.toolsUsedForGeneratingMigrationReport.split(",")) ||
      [];
    const isVMwareManagedServicesInScope = data?.isVMwareManagedServicesInScope;

    return {
      fundRequestId: id,
      claimAmount,
      awsAccountId,
      customerSignOffAttached,
      startDate,
      endDate,
      hasSowChanged,
      productionDate,
      numberOfVmwareToMigrate,
      totalNumberOfWorkloadsInUse,
      toolsUsedForGeneratingMigrationReport,
      isVMwareManagedServicesInScope,
    };
  };

  const saveDraft = async () => {
    if (formError) setFormError("");

    setIsLoading(true);
    const payload = createPayload();
    try {
      const response = await retryApiCall({
        callApi: updateCashClaim(payload, claimId),
        error: {},
      });
      if (hasError(response)) {
        const result: Result<ResultType> = handleApiError(response.errorType)(
          response
        );
        if (result && !result.success) {
          const errorResult = result as Result<ResultError<ResultErrorType>>;
          handleErrors({
            result: errorResult,
            setErrors: setErrors,
            setFormError: setFormError,
          });
          return;
        }
      }
      history.push(`/fund-requests/${id}/details`, {
        origin: RouteOrigin.ClaimSubmission,
        message: "Successfully saved cash claim.",
      });
    } catch (err) {
      console.error(err);
      setFormError(GENERIC_ERROR_MESSAGE);
    } finally {
      setIsLoading(false);
    }
  };

  const submit = async () => {
    if (formError) setFormError("");

    setIsLoading(true);
    if (data.hasSowChanged === null) {
      setRadioGroupErrorText("Scope of work (SoW) modification is required.");
    }
    const payload = createPayload();
    try {
      const response = await retryApiCall({
        callApi: submitCashClaim(payload, claimId),
        error: {},
      });
      if (hasError(response)) {
        const result: Result<ResultType> = handleApiError(response.errorType)(
          response
        );
        if (result && !result.success) {
          const errorResult = result as Result<ResultError<ResultErrorType>>;
          handleErrors({
            result: errorResult,
            setErrors: setErrors,
            setFormError: setFormError,
          });
          return;
        }
      }
      history.push(`/fund-requests/${id}/details`, {
        origin: RouteOrigin.ClaimSubmission,
        message: "Successfully submitted cash claim.",
      });
    } catch (err) {
      console.error(err);
      setFormError(GENERIC_ERROR_MESSAGE);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Form
      header={
        <Header
          variant="h1"
          info={
            <InfoLink
              onFollow={() => {
                setHelpPanelContent("claim.page");
              }}
            />
          }
        >
          Submit cash claim actual
        </Header>
      }
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <Button
            loading={isLoading}
            formAction="none"
            variant="link"
            onClick={() => {
              history.goBack();
            }}
          >
            Cancel
          </Button>
          <Button loading={isLoading} onClick={saveDraft}>
            Save draft
          </Button>
          <Button loading={isLoading} variant="primary" onClick={submit}>
            Submit cash claim actual
          </Button>
        </SpaceBetween>
      }
    >
      <SpaceBetween size="l">
        <CashClaimPlans {...stateProps} />
        <CashClaimActuals {...stateProps} />
        <CustomerSignOff {...stateProps} />
        <ConditionalField showField={vmwareEnabledFlag && vmwareClaim}>
          <VMwareMigrationCompletion {...stateProps} />
        </ConditionalField>
        <ConditionalField
          showField={
            vmwareMSPEnabledFlag &&
            vmwareClaim &&
            vmwareMSPAcknowledgement &&
            isVMwareManagedServicesInScope === "yes"
          }
        >
          <VMwareManagedServices {...stateProps} />
        </ConditionalField>
        <ClaimAdditionalArtifactsSection claimId={claimId} data={data} />
        <SystemErrorAlert isVisible={!!formError} error={formError} />
      </SpaceBetween>
    </Form>
  );
};

export default MAPCashClaimActualsSubmission;
