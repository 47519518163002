import React, { ReactNode } from "react";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import { ColumnLayout } from "@amzn/awsui-components-react";
import { IReadOnlyComponentProps } from "external/programs/migration-acceleration-program/2024/fund-request/types/SchemaTypes";
import { display } from "shared/util/common/display";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import ValueWithLabel from "shared/programs/migration-acceleration-program/2024/fund-request/components/fields/ValueWithLabel";
import useFeatureFlag from "../../../../../../../util/hooks/useFeatureFlag";
import { FEATURE_FLAG_ENUMS } from "../../../../../../../util/constants/featureFlagValues";
import { MAP2024_MIGRATION_PHASE } from "external/programs/migration-acceleration-program/2024/fund-request/components/create/eligibility/util";

const ReadOnlyMigrationEligibility = ({
  data,
  actions = <></>,
}: IReadOnlyComponentProps) => {
  const vmwareEnabledFlag = useFeatureFlag(
    FEATURE_FLAG_ENUMS.VMWARE_MAP_ENABLED
  );
  const vmwareMSPEnabledFlag = useFeatureFlag(
    FEATURE_FLAG_ENUMS.VMWARE_BONUS_MSP_OPTION_ENABLED
  );

  const definitions: {
    label: string;
    key: string;
    defaultValue?: string;
    format?: (value: unknown) => string | ReactNode;
  }[] = [
    {
      label: "Migration phase",
      key: "migrationPhase",
    },
    {
      label: "Annual recurring revenue (ARR) (USD)",
      key: "arrUsdAmount",
    },
    {
      label: "Migration eligible ARR (USD)",
      key: "eligibleArrUsdAmount",
    },
  ];

  if (data?.modernizationIncentiveEligible) {
    definitions.push({
      label: "Modernization ARR (USD)",
      key: "modernizationArrUsdAmount",
    });
  }

  if (
    vmwareEnabledFlag &&
    data?.migrationPhase === MAP2024_MIGRATION_PHASE.MOBILIZE
  ) {
    definitions.push({
      label: "VMware acknowledgement",
      key: "vmwareAcknowledgement",
      defaultValue: "No",
      format: (value) => (value ? "Yes" : "No"),
    });
  }

  if (vmwareMSPEnabledFlag && data?.vmwareAcknowledgement) {
    definitions.push({
      label: "VMware MSP acknowledgement",
      key: "vmwareMSPAcknowledgement",
      defaultValue: "No",
      format: (value) => (value ? "Yes" : "No"),
    });
  }

  return (
    <Container
      header={
        <Header variant="h2" actions={actions}>
          Migration details
        </Header>
      }
    >
      <ColumnLayout columns={3} variant="text-grid">
        {definitions.map(({ label, key, defaultValue, format }) => {
          return (
            <SpaceBetween key={key} size="l">
              <ValueWithLabel label={label}>
                {display({ data, key, defaultValue, format })}
              </ValueWithLabel>
            </SpaceBetween>
          );
        })}
      </ColumnLayout>
    </Container>
  );
};

export default ReadOnlyMigrationEligibility;
