import React from "react";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import { ColumnLayout } from "@amzn/awsui-components-react";
import { IReadOnlyComponentProps } from "external/programs/migration-acceleration-program/2024/fund-request/types/SchemaTypes";
import { display } from "shared/util/common/display";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import ValueWithLabel from "shared/programs/migration-acceleration-program/2024/fund-request/components/fields/ValueWithLabel";
import ConditionalField from "shared/components/common/Conditional/ConditionalField";
import useFeatureFlag from "shared/util/hooks/useFeatureFlag";
import { FEATURE_FLAG_ENUMS } from "shared/util/constants/featureFlagValues";
import { MAP2024_MIGRATION_PHASE } from "../../../eligibility/util";

const WizardReadOnlyMigrationEligibility = ({
  data,
  actions = <></>,
}: IReadOnlyComponentProps) => {
  const migrationPhase = data["migrationPhase"];
  const vmwareAcknowledgement = data["vmwareAcknowledgement"];
  const vmwareEnabledFlag = useFeatureFlag(
    FEATURE_FLAG_ENUMS.VMWARE_MAP_ENABLED
  );
  const vmwareMSPEnabledFlag = useFeatureFlag(
    FEATURE_FLAG_ENUMS.VMWARE_BONUS_MSP_OPTION_ENABLED
  );

  return (
    <Container
      header={
        <Header variant="h2" actions={actions}>
          Migration details
        </Header>
      }
    >
      <ColumnLayout columns={2} variant="text-grid">
        <SpaceBetween size="l">
          <ValueWithLabel label="Migration phase">
            {display({ data, key: "migrationPhase" })}
          </ValueWithLabel>
          <ValueWithLabel label="Migration eligible ARR (USD)">
            {display({
              data,
              key: "eligibleArrUsdAmount",
            })}
          </ValueWithLabel>
          <ConditionalField
            showField={
              vmwareEnabledFlag &&
              migrationPhase === MAP2024_MIGRATION_PHASE.MOBILIZE
            }
          >
            <ValueWithLabel label="VMware acknowledgement">
              {display({
                data: data,
                key: "vmwareAcknowledgement",
                defaultValue: "No",
                format: (value) => (value ? "Yes" : "No"),
              })}
            </ValueWithLabel>
          </ConditionalField>
        </SpaceBetween>
        <SpaceBetween size="l">
          <ValueWithLabel label="Annual recurring revenue (ARR) (USD)">
            {display({
              data,
              key: "arrUsdAmount",
            })}
          </ValueWithLabel>
          <ConditionalField showField={!!data["modernizationArrUsdAmount"]}>
            <ValueWithLabel label="Modernization ARR (USD)">
              {display({
                data,
                key: "modernizationArrUsdAmount",
              })}
            </ValueWithLabel>
          </ConditionalField>
          <ConditionalField
            showField={vmwareMSPEnabledFlag && vmwareAcknowledgement}
          >
            <ValueWithLabel label="VMware MSP acknowledgement">
              {display({
                data: data,
                key: "vmwareMSPAcknowledgement",
                defaultValue: "No",
                format: (value) => (value ? "Yes" : "No"),
              })}
            </ValueWithLabel>
          </ConditionalField>
        </SpaceBetween>
      </ColumnLayout>
    </Container>
  );
};

export default WizardReadOnlyMigrationEligibility;
